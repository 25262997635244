import http from "../../Http/Http-processcommon";
import http2 from "../../Http/Http-processcommon2";
const NombreDelModulo = "Hosts";

class GenerarURLDataService {
    async GetURL(clave) {
        return await http.get(`/${NombreDelModulo}/Ingresar?clave=${clave}`);
    }
    async Comenzar(id=0) {
        return await http.get(`/${NombreDelModulo}/IniciarSesion?id=${id}`);
    }

    async GetTinyUrl(data)
    {
        return await http2.post('/create', data)
    }
}

export default new GenerarURLDataService();
