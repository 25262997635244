import http from "../../Http/Http-processcommon";
const NombreDelModulo = "Hosts";

class PreguntasAbiertasDataService {
    async getAll(id) {
        return await http.get(`/${NombreDelModulo}/GetPreguntas?id=${id}`);
        // return await http.get(`/${NombreDelModulo}?idFilter=${idFilter}&search=${search}&page=${page}&rows=${rows}`);
    }
    async getEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/GetEtapa?idEtapa=${idEtapa}`);
    }

    async getAvance(idEtapa, idPregunta) {
        return await http.get(`/${NombreDelModulo}/GetAvanceEtapaPreguntaSesion?IdEtapa=${idEtapa}&IdPregunta=${idPregunta}`);
    }

    async activarEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/ActivarEtapa?idEtapa=${idEtapa}`);
    }
    async desactivarEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/DesactivarEtapa?idEtapa=${idEtapa}`);
    }
    async moverEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/MoverEtapa?idEtapa=${idEtapa}`);
    }
    async patch(idEtapa, id, ban) {
        return await http.get(`/${NombreDelModulo}/patch?idEtapa=${idEtapa}&id=${id}&ban=${ban}`);
    }

    //CAMBIOS PREGUNTASESION
    async getAllV2(id) {
        return await http.get(`/${NombreDelModulo}/GetPreguntasSesion?id=${id}`);
    }

    //async getEtapaV2(idEtapa) {
    //    return await http.get(`/${NombreDelModulo}/GetEtapaV2?idEtapa=${idEtapa}`);
    //}

    async getAvanceV2(idEtapa, idPregunta) {
        return await http.get(`/${NombreDelModulo}/GetAvanceEtapaPreguntaSesionV2?IdEtapa=${idEtapa}&IdPregunta=${idPregunta}`);
    }

    //async activarEtapaV2(idEtapa) {
    //    return await http.get(`/${NombreDelModulo}/ActivarEtapa?idEtapa=${idEtapa}`);
    //}
    //async desactivarEtapaV2(idEtapa) {
    //    return await http.get(`/${NombreDelModulo}/DesactivarEtapa?idEtapa=${idEtapa}`);
    //}
    //async moverEtapaV2(idEtapa) {
    //    return await http.get(`/${NombreDelModulo}/MoverEtapa?idEtapa=${idEtapa}`);
    //}
    async patchV2(idEtapa, id, ban) {
        return await http.get(`/${NombreDelModulo}/patchV2?idEtapa=${idEtapa}&id=${id}&ban=${ban}`);
    }
}

export default new PreguntasAbiertasDataService();
