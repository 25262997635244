import http from "../../Http/Http-processcommon";
const NombreDelModulo = "Hosts";
const Modulo = "Categoria";
const RespuestaCat = 'Usuarios'
class PreguntaBrechaDataService {
    async getAll(id) {
        return await http.get(`/${NombreDelModulo}/GetPreguntas?id=${id}`);
        // return await http.get(`/${NombreDelModulo}?idFilter=${idFilter}&search=${search}&page=${page}&rows=${rows}`);
    }
    async getIdEtapa() {
        return await http.get(`/${NombreDelModulo}/GetIdEtapa`);
    }
    async getAvance(idEtapa, idPregunta) {
        return await http.get(`/${NombreDelModulo}/GetAvanceEtapaPreguntaSesion?IdEtapa=${idEtapa}&IdPregunta=${idPregunta}`);
    }
    async getAvanceCategorizar() {
        return await http.get(`/${RespuestaCat}/GetAvanceEtapaCalificacion`);
    }
    async getEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/GetEtapa?idEtapa=${idEtapa}`);
    }
    async activarEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/ActivarEtapa?idEtapa=${idEtapa}`);
    }
    async desactivarEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/DesactivarEtapa?idEtapa=${idEtapa}`);
    }
    async moverEtapa(idEtapa) {
        return await http.get(`/${NombreDelModulo}/MoverEtapa?idEtapa=${idEtapa}`);
    }

    async RespuestasBrecha(idSesion) {
        return await http.get(`/${NombreDelModulo}/RespuestasBrecha?idSesion=${idSesion}`);
    }

    async UpdateCategoria(id, data) {
        return await http.put(`/${Modulo}/${id}`, data)
    }
    async AddCategoria(data) {
        return await http.post(`/${Modulo}`, data)
    }
    async getAllCategoria(session) {
        return await http.get(`/${Modulo}/GetSession?session=${session}`)
    }
    async addRespuestaCategoria(data) {
        return await http.post(`/${RespuestaCat}/PostRespuestaCategoria`, data)
    }
    async AddRespCat(data) {
        return await http.post(`/${RespuestaCat}/AddRespuestasCategoriaIA`, data)
    }
    async GetRespuestasCategoriaIA() {
        return await http.get(`/${RespuestaCat}/GetRespuestasCategoriaIA`, )
    }

    async EnviarRespuestasApi(data) {
        return await http.post('https://abp-flask-api.azurewebsites.net/api/v1/categorias', data)
    }

    async getCategoriasAprobadas(session) {
        return await http.get(`/${Modulo}/GetAprobadas?session=${session}`)
    }

    async ObtenerUsuarios(session) {
        return await http.get(`/${RespuestaCat}/ObtenerUsuarios?session=${session}`)
    }

    async GetAvance(session) {
        return await http.get(`/${Modulo}/GetAvance?session=${session}`)
    }

    async FusionarCategoria(idQuitar, idAgregar, session) {
        return await http.get(`/${Modulo}/FusionarCategoria?idQuitar=${idQuitar}&idAgregar=${idAgregar}&session=${session}`)
    }

    //ACTUALIZACION

    async getAvanceV2(idEtapa, idPregunta) {
        return await http.get(`/${NombreDelModulo}/GetAvanceEtapaPreguntaSesionV2?IdEtapa=${idEtapa}&IdPregunta=${idPregunta}`);
    }

    async getAllV2(id) {
        return await http.get(`/${NombreDelModulo}/GetPreguntasSesion?id=${id}`);
        // return await http.get(`/${NombreDelModulo}?idFilter=${idFilter}&search=${search}&page=${page}&rows=${rows}`);
    }

    async respuestasCategoria(id) {
        try {
            var res = await http.get(`/${RespuestaCat}/getRespuestasCategoria?Id=${id}`)
            return res;
        } catch (error) {
            console.log(error, "Error en el catch");
            return error;
        }
    }
}

export default new PreguntaBrechaDataService();
