import React from 'react';
import GenerarURLDataService from "../../Services/GenerarURL/GenerarURL.Service";
import Spinner from '../../components/Spinner';
import { useParams } from 'react-router-dom';
class GenerarURL extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sesion: {
                idSesion: 0,
                idInicio: 0,
                idEtapa: 0,
                link: "",
                token: "",
                activo: false
            },
            clave: "",
            url: "",
            urlObtenida: false,
            infoModal: false,
            tinyurl: "",
            sesionProgramada: {
                urlQr: "",
                urlCorta:""
            },
            etapa:null,
        }
    }

    // Handlers
    handlerChangeClave = async (value) => {
        this.setState(prevState => ({
            ...prevState,
            clave: value
        }));
    }
    //
    componentDidMount = async () => {
      
        estheticIn(); // estética del sitio, no borrar

        if (this.props?.match?.params?.token !== "") {
            await this.GetURLV2(this.props?.match?.params?.token);
        }
    }

    componentWillUnmount = async () => {
        estheticOut(); // estética del sitio, no borrar
    }
    //
    GetURLV2 = async (token) => {
        let sesion = {};

        this.setState({ loading: true })
        await GenerarURLDataService.GetURL(token)
            .then(response => {
                console.log(response)
                if (response.data) {
                    sesion = response.data.sesion;
                    this.setState({ sesionProgramada: response.data.sesionProgramada });
                    this.setState({ etapa: response.data.etapa })
                }
            })
        let tinyData = {
            url: sesion.link,
            domain: "tinyurl.com"
        };
        //console.log(tinyData);
        // await GenerarURLDataService.GetTinyUrl(tinyData)
        // .then( response => {
        //     //console.log(response.data);
        //     let data = response.data.data;
        //     // console.log(data);
        //     // console.log(data.tiny_url);
        //     this.setState(prevState =>({
        //         ...prevState,
        //         tinyurl: data.tiny_url
        //     }))
        // });
        //console.log(sesion, "----------->>", this.state.sesionProgramada)
        this.setState(prevState => ({
            ...prevState,
            sesion: sesion,
            urlObtenida: true
        }))
        localStorage.removeItem("keyJwt");
        //navigator.clipboard.writeText(sesion.link);
        //navigator.clipboard.writeText(this.state.tinyurl);
        localStorage.setItem('keyJwt', sesion.token)
        localStorage.setItem('abplink', sesion.link)

        if (this.state.etapa !== null) {
            const finalizado = this.state.etapa.finalizado;
            const idEtapa = this.state.etapa.idEtapa;
            const sesionId = this.state.sesion.idSesion;
            const keyJwt = localStorage.getItem("keyJwt");
            console.log(this.state.etapa)
            switch (idEtapa) {
                case 1:
                    this.props.history.push(finalizado ? `/pregunta-brecha/${sesionId}` : "/preguntas-abiertas");
                    break;
                case 2:
                    this.props.history.push(finalizado ? `/pregunta-brecha/${sesionId}` : "/preguntas-abiertas");
                    break;
                case 3:
                    this.props.history.push(finalizado ? `/pregunta-brecha/${sesionId}` : "/preguntas-abiertas");
                    break;
                case 4:
                    this.props.history.push(finalizado ? "/pregunta-final/" : `/pregunta-brecha/${sesionId}`);
                    break;
                case 5:
                    this.props.history.push(finalizado ? `/home/${keyJwt}` : "/pregunta-final/");
                    break;
                default:
                    this.props.history.push(`/home/${keyJwt}`);
                    break;
            }
        }


        this.setState({ loading: false })
    }

    Descargar = () => {

        let link = document.createElement('a');
        link.href = this.state.sesionProgramada.urlQr;
        link.setAttribute('download', 'QR');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Métodos
    GetURL = async () => {        
        //let sesion = {};
        //let clave = this.state.clave;
        //if(clave !== "" && !this.state.urlObtenida){
        //    this.setState({loading: true})
        //    await GenerarURLDataService.GetURL(clave)
        //    .then( response => {
        //        sesion = response.data;
        //    })
        //    let tinyData = {
        //        url : sesion.link,
        //        domain: "tinyurl.com"
        //    };
        //    //console.log(tinyData);
        //    // await GenerarURLDataService.GetTinyUrl(tinyData)
        //    // .then( response => {
        //    //     //console.log(response.data);
        //    //     let data = response.data.data;
        //    //     // console.log(data);
        //    //     // console.log(data.tiny_url);
        //    //     this.setState(prevState =>({
        //    //         ...prevState,
        //    //         tinyurl: data.tiny_url
        //    //     }))
        //    // });

        //    this.setState(prevState =>({
        //        ...prevState,
        //        sesion: sesion,
        //        urlObtenida: true
        //    }))
        //    localStorage.removeItem("keyJwt");
        //    navigator.clipboard.writeText(sesion.link);
        //    //navigator.clipboard.writeText(this.state.tinyurl);
        //    localStorage.setItem('keyJwt', sesion.token)
        //    localStorage.setItem('abplink',sesion.link)
        //    this.setState({loading: false})
        //}else if(clave === ""){
        //    setInputErrors();
        //}else if(this.state.urlObtenida){
        //    navigator.clipboard.writeText(this.state.sesion.link);
        //}
    }

    Comenzar = async () => {
        let responseData = null;
        await GenerarURLDataService.Comenzar(this.state.sesion.idSesion)
        .then(response => {
            responseData = response.data;
        });
        if(responseData != null)
            this.props.history.push('/preguntas-abiertas');
    }
    //
    render() {
        return (
            <div className="page-container">
                <Spinner show={this.state.loading} />
               <div className="title"><h1>ABP</h1></div>
                <div className="GenerateURLContainer"> {/*onClick={async () => { await this.GetURL() }}*/}
                    <div className="GenerateURL">
                        <div className="wave animated"></div>
                        <div className="wave animated"></div>
                        <div className="wave animated"></div>
                        <div className="icon">
                            {this.state.sesionProgramada.urlQr === "" && <>  <img width="200" alt="ABP" src="/img/1Recurso 11.png"></img> </>}
                            {this.state.sesionProgramada.urlQr !== "" && <> <img width="300" alt="QR" src={`${this.state.sesionProgramada.urlQr}?${Date.now}`}></img> </>}
                        </div>
                        {/*<div className="message">{this.state.sesion.link === "" || this.state.sesion.link === null ? "Generar URL" : "Copiar URL"}</div>*/}
                        {(this.state.sesion.link !== "" && this.state.sesion.link !== null) && <> <div onClick={() => this.Descargar()} className="message">{"Descargar QR"}</div> </>} 
                    </div>
               </div>
                <div className="url mt-5">
                    <p>{this.state.sesionProgramada.urlCorta}</p>
                    {/*<div onClick={() => this.Descargar()} className="messageLink text-center">{!this.state.urlObtenida ? "" : "Copiar enlace" }</div>*/}
                    
               </div>
               {/*<div className="form-group flex-center flex-see-column">*/}
               {/*    <label>Clave de desbloqueo</label>*/}
               {/*     <div className="input-group">*/}
               {/*         <input className="text-center" type="password" onChange={(e)=>{this.handlerChangeClave(e.currentTarget.value)}} value={this.state.clave}/>*/}
               {/*     </div>*/}
               {/*</div>*/}
               {
                    !this.state.urlObtenida ?
                        <button disabled="disabled" className="btn" type="button">Comenzar encuesta</button>
                    :
                        <>
                            {this.state.etapa === null && <button onClick={() => { this.Comenzar() }} className="btn primary" type="button">Comenzar encuesta</button>}
                        </>
               }
               <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                   <div className="modalMessage">
                       <div className="modalHeader">
                           <p>ABP Virtual</p>
                           <small>Sistema de consulta participativa</small>
                       </div>
                       <div className="modalBody">
                           <p>El sistema de consulta participativa ABP Virtual permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                           <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                       </div>
                       <div className="modalFooter">
                           <button type="button" onClick={()=>{this.setState({infoModal: false})}} className="btn primary">Cerrar mensaje</button>
                       </div>
                   </div>
               </div>
               <div className="leftActions">
                   <button type="button" onClick={()=>{this.setState({infoModal: true})}}><i className="fas fa-info" type="button"></i></button>
               </div>
            </div>
        )
    }
}

function estheticIn(){
    let body = document.querySelector('body');
    if(body){
        let waves = body.querySelectorAll('.wave');
        setTimeout(() => {
            waves.forEach(x => {
                x.classList.add('active');
            })
        }, 10);
    }
}
function estheticOut(){
    let body = document.querySelector('body');
    if(body){
        let waves = body.querySelectorAll('.wave');
        waves.forEach(x => {
            x.classList.remove('active');
        })
    }
}
function setInputErrors(){
    let body = document.querySelector('body');
    if(body){
        let inputs = body.querySelectorAll('input');
        inputs.forEach(x => {
            x.classList.add("error-detected");
        });
        setTimeout(() => {
            inputs.forEach(x => {
                x.classList.remove("error-detected");
            }); 
        }, 1000);
    }
}
export default GenerarURL;