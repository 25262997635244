import React from 'react';
import SwitchCheckbox from '../../components/SwitchCheckbox/SwitchCheckbox';
import PreguntasAbiertasDataService from '../../Services/PreguntasAbiertas/PreguntasAbiertas.Service'
import Spinner from '../../components/Spinner';
import PreguntaBrechaService from '../../Services/PreguntaBrecha/PreguntaBrecha.Service';

class ControlPreguntasAbiertas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            preguntas: [],
            etapa: {
                idSesion: 0,
                idEtapa: 1,
                activo: false,
                finalizado: false
            },
            pregunta1Enabled: false,
            pregunta2Enabled: false,
            pregunta3Enabled: false,
            etapaActiva: true,
            textoAvance1: "",
            textoAvance2: "",
            textoAvance3: "",
            haComenzado: false,
            infoModal: false,
            countUsersSession: 0,
        }
    }

    // Handlers
    handlerChanguePregunta1 = async (value) => {
        console.log(value)

        this.setState({ pregunta1Enabled: value, pregunta2Enabled: false, pregunta3Enabled: false })
        await this.setActivePregunta(1, value);
    }
    handlerChanguePregunta2 = async (value) => {
        console.log(value)

        this.setState({ pregunta1Enabled: false, pregunta2Enabled: value, pregunta3Enabled: false })
        await this.setActivePregunta(2, value);
    }
    handlerChanguePregunta3 = async (value) => {
        console.log(value)

        this.setState({ pregunta1Enabled: false, pregunta2Enabled: false, pregunta3Enabled: value })
        await this.setActivePregunta(3, value);
    }
    //
    componentDidMount = async () => {
        await this.getEtapa();
        await this.getAllPreguntas();
        for (var i = 0; i < this.state.preguntas.length; i++) {
            if (i === 0) {
                this.setState({ pregunta1Enabled: this.state.preguntas[i].activo });
            }
            else if (i === 1) {
                this.setState({ pregunta2Enabled: this.state.preguntas[i].activo });
            }
            else if (i === 2) {
                this.setState({ pregunta3Enabled: this.state.preguntas[i].activo });
            }

            if (this.state.preguntas[i].activo)
                this.setTextoAvance(this.state.preguntas[i].idPregunta)
        }
        this.refreshData();
    }
    //
    // Métodos
    getAllPreguntas = async () => {
        let preguntas = this.state.preguntas;
        //await PreguntasAbiertasDataService.getAll(1)
        await PreguntasAbiertasDataService.getAllV2(1)
            .then(response => {
                preguntas = response.data;
            });
        console.log(preguntas);
        this.setState(prevState => ({
            ...prevState,
            preguntas: preguntas
        }));
    }

    getIdPregunta() {
        var idPregunta = 0;

        for (var i = 0; i < this.state.preguntas.length; i++) {
            if (this.state.preguntas[i].activo) {
                idPregunta = this.state.preguntas[i].idPregunta;
                break;
            }
        }
        // if(ban)
        // {
        //     idPregunta = this.state.preguntas[id-1].idPregunta;
        // }
        return idPregunta;
    }

    getOrdenPregunta() {
        for (var i = 0; i < this.state.preguntas.length; i++) {
            if (this.state.preguntas[i].activo) {
                return i;
            }
        }
        return 0;
    }

    setTextoAvance = async (idPregunta) => {
        console.log(this.state.etapa.activo, "------>", idPregunta);
        var index = this.getOrdenPregunta();

        if (this.state.etapa.activo) {
            await PreguntasAbiertasDataService.getAvanceV2(1, idPregunta)
                .then(response => {
                    console.log(response.data);
                    if (index === 0)
                        this.setState({ textoAvance1: response.data + " de " + this.state.countUsersSession + " personas han respondido esta pregunta" })
                    else if (index === 1)
                        this.setState({ textoAvance2: response.data + " de " + this.state.countUsersSession + " personas han respondido esta pregunta" })
                    else if (index === 2)
                        this.setState({ textoAvance3: response.data + " de " + this.state.countUsersSession + " personas han respondido esta pregunta" })
                });
        }

 
        //console.log("end Texto avance")
    }

    setActivePregunta = async (id, ban) => {
        this.setState({ loading: true })
        let preguntas = this.state.preguntas;
        //await PreguntasAbiertasDataService.patch(1, id, ban)
        await PreguntasAbiertasDataService.patchV2(1, id, ban)
            .then(response => {
                preguntas = response.data;
            });
        this.setState(prevState => ({
            ...prevState,
            loading: false,
            preguntas: preguntas
        }));
        var idPregunta = this.getIdPregunta();
        await this.setTextoAvance(idPregunta);
        //Volver a activar el contador

    }

    refreshData = async () => {
        try {
            const timeoutId = setTimeout(async () => {
                //console.log("refreshData");
                await this.setTextoAvance(this.getIdPregunta());
                await this._getUsersSession()

                //onsole.log("End Refresh Data");
            }, 5000);
        }
        catch (e) {

        }
        finally {
            if (this.state.etapaActiva)
                setTimeout(async () => this.refreshData(), 3000);
        }
    }

    getEtapa = async () => {
        let responseData = null;
        await PreguntasAbiertasDataService.getEtapa(1)
            .then(response => {
                responseData = response.data;
            });

        if (responseData !== null) {
            this.setState(prevState => ({
                ...prevState,
                etapa: responseData
            }));
        }
    }

    activarEtapa = async () => {
        let responseData = null;
        await PreguntasAbiertasDataService.activarEtapa(1)
            .then(response => {
                responseData = response.data;
                console.log(responseData);
            });

        if (responseData !== null) {
            this.setState(prevState => ({
                ...prevState,
                etapa: responseData
            }));
            await this.setActivePregunta(1, true);
        }
    }

    desactivarEtapa = async () => {
        this.setState({ loading: true })
        let responseData = null;
        await PreguntasAbiertasDataService.desactivarEtapa(1)
            .then(response => {
                responseData = response.data;
            });
        if (responseData !== null) {
            await this.moverEtapa();
        }
        this.setState({ loading: false })
    }

    moverEtapa = async () => {
        let responseData = null;
        await PreguntasAbiertasDataService.moverEtapa(2)
            .then(response => {
                responseData = response.data;
            });
        if (responseData != null) {
            console.log("desactivar etapa");
            this.setState({ etapaActiva: false })
            this.props.history.push(`/pregunta-brecha/${this.state.etapa.idSesion}`);
        }
        // this.props.history.push("/pregunta-brecha");
    }

    Comenzar = async () => {
        this.setState({ haComenzado: true, pregunta1Enabled: true })
        await this.activarEtapa();
    }

    Finalizar = async () => {
        await this.desactivarEtapa();
    }


    // ! Get countUsersSession
    _getUsersSession = async () => {
        let res = await PreguntaBrechaService.ObtenerUsuarios(this.state.etapa?.idSesion).then(res => res)
        if (res?.data) {
            this.setState({ countUsersSession: res.data })
        }
    }
    //
    render() {
        return (
            this.state.etapa.finalizado !== true ?
                <div className="page-container">
                    <Spinner show={this.state.loading} />
                    <div className="title"><h1>etapa de preguntas abiertas</h1></div>
                    <div className="questions-container">
                        <div className="question">
                            <label htmlFor="">Primera pregunta</label>
                            <div className="description text-justify">
                                <p>{this.state.preguntas[0] !== undefined ? this.state.preguntas[0].nombre || "" : ""}</p>
                                <SwitchCheckbox className="action" checked={this.state.preguntas[0] !== undefined ? this.state.preguntas[0].activo || false : false} handlerChange={this.handlerChanguePregunta1} />
                            </div>
                            <label style={{ display: this.state.pregunta1Enabled ? "block" : "none" }}>{this.state.textoAvance1}</label>
                        </div>
                        <div className="question">
                            <label htmlFor="">Segunda pregunta</label>
                            <div className="description text-justify">
                                <p>{this.state.preguntas[1] !== undefined ? this.state.preguntas[1].nombre || "" : ""}</p>
                                <SwitchCheckbox className="action" checked={this.state.preguntas[1] !== undefined ? this.state.preguntas[1].activo || false : false} handlerChange={this.handlerChanguePregunta2} />
                            </div>
                            <label style={{ display: this.state.pregunta2Enabled ? "block" : "none" }}>{this.state.textoAvance2}</label>
                        </div>
                        <div className="question">
                            <label htmlFor="">Tercera pregunta</label>
                            <div className="description text-justify">
                                <p>{this.state.preguntas[2] !== undefined ? this.state.preguntas[2].nombre || "" : ""}</p>
                                <SwitchCheckbox className="action" checked={this.state.preguntas[2] !== undefined ? this.state.preguntas[2].activo || false : false} handlerChange={this.handlerChanguePregunta3} />
                            </div>
                            <label style={{ display: this.state.pregunta3Enabled ? "block" : "none" }}>{this.state.textoAvance3}</label>
                        </div>
                    </div>

                    {
                        !this.state.etapa.activo ?
                            <button onClick={() => { this.Comenzar() }} className="btn primary" type="button">Comenzar preguntas abiertas</button>
                            : !this.state.etapa.finalizado ?
                                <button onClick={() => { this.Finalizar() }} className="btn danger" type="button">Finalizar preguntas abiertas</button> :
                                <button className="btn" type="button" disabled="disabled" >Etapa finalizada</button>
                    }
                    <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                        <div className="modalMessage">
                            <div className="modalHeader">
                                <p>ABP Virtual</p>
                                <small>Sistema de consulta participativa</small>
                            </div>
                            <div className="modalBody">
                                <p>El sistema de consulta participativa ABP Virtual permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                                <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                            </div>
                            <div className="modalFooter">
                                <button type="button" onClick={() => { this.setState({ infoModal: false }) }} className="btn primary">Cerrar mensaje</button>
                            </div>
                        </div>
                    </div>
                    <div className="leftActions">
                        <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("abplink")) }}><i className="fas fa-link" type="button"></i></button>
                        <button type="button" onClick={() => { this.setState({ infoModal: true }) }}><i className="fas fa-info" type="button"></i></button>
                    </div>
                </div>
                :
                <div className="page-container">
                    <br />
                    La etapa no esta activa
                </div>
        )
    }
}

export default ControlPreguntasAbiertas;