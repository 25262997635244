// import React from 'react';
// import { useState } from 'react';
import React, { useState } from 'react';
import PreguntaBrechaDataService from '../../Services/PreguntaBrecha/PreguntaBrecha.Service';
import PreguntaFinalDataService from '../../Services/PreguntaFinal/PreguntaFinal.Service';
import Spinner from '../../components/Spinner';
import { ToastContainer, toast } from 'react-toastify';
// import CircularProgress from '@mui/material/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import { Table, Input, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';
// const [modal, setModal] = useState();

const Estilos = {
    div: {
        justifyContent: 'space-between',
        display: 'flex',
        padding: '10px',
        border: '1px solid #94a3b8',
        marginBottom: 15,
        width: '25%',
        height: 'auto',
        borderRadius: '8px',
        cursor: 'pointer', // Para indicar que es clickeable
    },
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    },
    tex: {
        fontSize: '20px',
    },
    respuestas: {
        textAlign: 'center',
    },
};

class ControlPreguntaBrecha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            etapa: {
                idSesion: 0,
                idEtapa: 2,
                activo: false,
                finalizado: false
            },
            preguntas: [],
            categorias: [],
            etapaUnoInicio: true,
            haComenzadoUno: false,
            haComenzadoDos: false,
            haComenzadoTres: false,
            textoAvanceBrecha: "_____",
            infoModal: false,
            opcion: 0,
            respuestasCategorias: [],
            respuestasSinCategoria: [],
            editarCategoriaModal: false,

            categoria: {
                activo: 1,
                esDefault: 1,
                idCategoria: 0,
                nombre: "",
                session: 0
            },
            posicion: 0,
            datosRespuestasCategoria: [],
            statusAdCat: false,
            porcentaje: 0,
            idSesion: this.props.match.params.id,
            respuestasEnviarApi: [],
            CategoriasRespuestas: [],
            countUsersSession: 0,
            idSetTimeout: null,
            modalFusionar: false,

            btnDissabled: false,
            // ? variables fusionar categorias
            idCategoriaQuitar: 0,
            idCategoriaAAgregar: null,


            //Tabla
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 10,
            respuestasCategoria: [],
            modalRespuestasCategoria: false,
            selectedCategories: [],
            selectedIdPregunta: 0,
            selectedIdCategoriaIA: 0,
        }
    }

    handleSearch = (event) => {
        this.setState({ searchTerm: event.target.value, currentPage: 1 });
    };

    handleClick = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };


    // Handlers

    //
    componentDidMount = async () => {
        await this.clickTap(2);
        await this.getAllPreguntas();
        await this.getCategorias();
        this.MostrarTextoAvance();
        this.refreshData();
        await this.getIdEtapa()

        if (this.state.etapa.idEtapa == 3 && this.state.etapa.activo == true) {
            this.setState({ loading: true });

            await this.GetRespuestasCategoriaIA()
            await this._getCategoriasAprobadas()
            this.setState({ loading: false });


            if (this.state.respuestasCategorias.length == 0 && this.state.CategoriasRespuestas.length == 0)
                await this._preguntasBrecha();
            //if (this.state.CategoriasRespuestas.length == 0)
            //    await this._preguntasBrecha();
        }
    }
    //

    // Métodos
    _preguntasBrecha = async () => {
        this.state.respuestasEnviarApi.length = 0
        try {
            let res = await PreguntaBrechaDataService.RespuestasBrecha(this.state.idSesion).then((data) => data)
            // console.log(res);
            if (res.status == 200) {
                res.data.map((data) => {
                    this.state.respuestasEnviarApi.push({
                        id: data.idRespuesta,
                        respuesta: data.respuesta,
                    })
                })

                if (this.state.respuestasEnviarApi.length > 0) {
                    await this.EnviarRespuestas({
                        mensajes: this.state.respuestasEnviarApi,
                        contexto: this.state.preguntas.contextoIA ?? ""
                    })
                }
                //else {
                //    await this.desactivarEtapa(this.state.etapa.idEtapa)
                //}

            }
        } catch (error) {
            console.log(error);
        }
    }

    //EnviarRespuestas = async (data) => {
    //    try {
    //        let res = await PreguntaBrechaDataService.EnviarRespuestasApi(data).then(data => data)
    //        if (res?.data?.data?.categorias_unicas) {
    //            //console.log(res.data.data, "<<------------->>");
    //            var datosGuardar = []

    //            res.data.data.categorias_unicas.map((data) => {

    //                this.state.categoria = {
    //                    activo: 1,
    //                    esDefault: 1,
    //                    idCategoria: 0,
    //                    nombre: data,
    //                    session: this.state.idSesion
    //                }

    //                let objeto = {
    //                    categoria: {
    //                    },
    //                    respuestas: []
    //                }

    //                let result = await this.AddCategoria2()
    //                if (result) {
    //                    objeto.categoria = {
    //                        id: result.idCategoria,
    //                        nombre: data,
    //                        session: this.state.idSesion
    //                    }

    //                    res.data.data.categorias_respuestas.map((da) => {
    //                        if (da.categoria == data) {
    //                            this.state.respuestasEnviarApi.map((d) => {
    //                                if (da.id == d.id) {
    //                                    objeto.respuestas.push(
    //                                        {
    //                                            id: da.id,
    //                                            respuesta: d.respuesta
    //                                        }
    //                                    )
    //                                }
    //                            })
    //                        }
    //                    })

    //                    objeto.respuestas.map((res, j) => {
    //                        console.log(res)
    //                        var add = {
    //                            id: 0,
    //                            idCategoria: objeto.categoria.id,
    //                            idRespuesta: res.id
    //                        }
    //                        datosGuardar.push(add);
    //                    })

    //                    this.state.respuestasCategorias.push(objeto)
    //                }
    //            })


    //            //this.state.respuestasCategorias.map((data, k) => {
    //            //    data.respuestas.map((res, j) => {
    //            //        console.log(res)
    //            //        var add = {
    //            //            id: 0,
    //            //            idCategoria: data.categoria.id,
    //            //            idRespuesta: res.id
    //            //        }

    //            //        datosGuardar.push(add);
    //            //    })
    //            //})
    //            //console.log(this.state.respuestasCategorias, "<<------------->>");
    //            console.log(datosGuardar, "<<------------->>");

    //            if (datosGuardar.length > 0) {
    //                await this.AddRespCat(datosGuardar);
    //            }

    //            this.setState({ loading: false })
    //        }
    //    } catch (error) {

    //    }
    //}

    EnviarRespuestas = async (data) => {
        try {
            let res = await PreguntaBrechaDataService.EnviarRespuestasApi(data);
            if (res?.data?.data?.categorias_unicas) {
                var datosGuardar = [];

                for (let data of res.data.data.categorias_unicas) {
                    this.state.categoria = {
                        activo: 1,
                        esDefault: 1,
                        idCategoria: 0,
                        nombre: data,
                        session: this.state.idSesion
                    };

                    let objeto = {
                        categoria: {},
                        respuestas: []
                    };

                    let result = await this.AddCategoria2();
                    if (result) {
                        objeto.categoria = {
                            id: result.idCategoria,
                            nombre: data,
                            session: this.state.idSesion
                        };

                        for (let da of res.data.data.categorias_respuestas) {
                            if (da.categoria == data) {
                                for (let d of this.state.respuestasEnviarApi) {
                                    if (da.id == d.id) {
                                        objeto.respuestas.push({
                                            id: da.id,
                                            respuesta: d.respuesta
                                        });
                                    }
                                }
                            }
                        }

                        for (let res of objeto.respuestas) {
                            console.log(res);
                            var add = {
                                id: 0,
                                idCategoria: objeto.categoria.id,
                                idRespuesta: res.id,
                                idSesion: this.state.etapa.idSesion,
                                activo: true
                            };
                            datosGuardar.push(add);
                        }

                        this.state.respuestasCategorias.push(objeto);
                    }
                }

                if (datosGuardar.length > 0) {
                    await this.AddRespCat(datosGuardar);
                }
                this.setState({ loading: false });
            }
        } catch (error) {
            console.error(error);
        }
    };


    AddRespCat = async (data) => {
        try {
            var res = await PreguntaBrechaDataService.AddRespCat(data).then(res => res)
        } catch (e) {
            console.log(e,"Error consulta front")
        }
    }

    GetRespuestasCategoriaIA = async (data) => {
        try {
            var res = await PreguntaBrechaDataService.GetRespuestasCategoriaIA().then(res => res)
            if (res?.data)
                this.setState({ respuestasCategorias: res.data })
            //console.log(this.state.respuestasCategorias, "---------> ######")
            this.setState({ loading: false });
        } catch (e) {
            console.log(e,"Error consulta front")
        }
    }


    getAllPreguntas = async () => {
        let preguntas = this.state.preguntas;
        //await PreguntaBrechaDataService.getAll(2)
        await PreguntaBrechaDataService.getAllV2(2)
            .then(response => {
                preguntas = response.data;
                console.log("getAllPreguntas");
                console.log(preguntas);
            });

        if (preguntas.length > 0)
            if (preguntas[0].activo) {
                this.setState({ haComenzadoUno: true });
            }
        this.setState(prevState => ({
            ...prevState,
            preguntas: preguntas
        }));
    }

    getCategorias = async () => {
        let categorias = this.state.categorias
        await PreguntaBrechaDataService.getAllCategoria(this.state.idSesion)
            .then(response => {
                categorias = response.data
            });
        this.setState(prevState => ({
            ...prevState,
            categorias: categorias
        }))

    }

    clickTap = async (id) => {
        let tabs = document.querySelectorAll(".NavigationTabs ul li");
        tabs.forEach((y) => {
            y.classList.remove("active");
        });
        await this.getEtapa(id);
        if (document.querySelector(".NavigationTabs ul li[data-ref='" + id + "']"))
            document.querySelector(".NavigationTabs ul li[data-ref='" + id + "']").click();

        switch (this.state.etapa?.idEtapa) {
            case 2:
                if (this.state.etapa?.activo)
                    this.setState({ haComenzadoUno: true })
                break;
            case 3:
                if (this.state.etapa?.activo)
                    this.setState({ haComenzadoDos: true })
                break;
            case 4:
                if (this.state.etapa?.activo) 
                    this.setState({ haComenzadoTres: true })
                break;
            default:
        }


        console.log(this.state.etapa, "------->>> Despues de terminar la etapa brecha")
    }

    getIdEtapa = async () => {
        let responseData = null;
        await PreguntaBrechaDataService.getIdEtapa()
            .then(response => {
                

                //switch (response.data) {
                //    case 2:
                //        console.log("1", response)
                //        this.setState({ haComenzadoUno: true })
                //        break;
                //    case 3:
                //        console.log("2", response)
                //        this.setState({ haComenzadoDos: true })
                //        break;
                //    case 4:
                //        console.log("3", response)
                //        this.setState({ haComenzadoUno: false })
                //        this.setState({ haComenzadoDos: false })
                //        this.setState({ haComenzadoTres: true })
                //        break;
                //    default:
                //}
                responseData = response.data;
                console.log(responseData);
            });

        if (responseData !== null) {
            await this.clickTap(responseData);
        }
    }

    getEtapa = async (id) => {
        let responseData = null;
        await PreguntaBrechaDataService.getEtapa(id)
            .then(response => {
                responseData = response.data;
            });

        if (responseData !== null) {
            this.setState(prevState => ({
                ...prevState,
                etapa: responseData
            }));
        }
    }

    setActivePregunta = async (id, ban) => {
        this.setState({ loading: true })
        let preguntas = this.state.preguntas;
        //await PreguntaFinalDataService.patch(2, id, ban)
        await PreguntaFinalDataService.patchV2(2, id, ban)
            .then(response => {
                preguntas = response.data;
            });
        this.setState(prevState => ({
            ...prevState,
            loading: false,
            preguntas: preguntas
        }));
        // var idPregunta = this.getIdPregunta();
        // await this.setTextoAvance(idPregunta);
    }

    activarEtapa = async (id) => {
        let responseData = null;
        await PreguntaBrechaDataService.activarEtapa(id)
            .then(response => {
                responseData = response.data;
            });
        if (responseData !== null) {
            this.setState(prevState => ({
                ...prevState,
                //loading: false,
                etapa: responseData
            }));
            await this.setActivePregunta(1, true);

        }
        // if (responseData !== null) {
        // }
    }

    desactivarEtapa = async (id) => {
        // this.setState({ loading: true })


        let responseData = null;
        await PreguntaBrechaDataService.desactivarEtapa(id)
            .then(response => {
                responseData = response.data;
            });
        if (responseData !== null) {
            await this.moverEtapa(this.state.etapa.idEtapa);
        }

        //Cuando termine la etapa dos, se desactiva el timer
        if (this.state.etapa.idEtapa == 3) {
            console.log("terminó la etapa 2");
            this.setState({ etapaUnoInicio: false });
        }

        this.setState({ loading: false })
    }

    moverEtapa = async (id) => {
        let responseData = null;
        await PreguntaBrechaDataService.moverEtapa(id + 1)
            .then(response => {
                responseData = response.data;
            });
        if (responseData != null) {
            if (responseData.idEtapa === 5)
                this.props.history.push("/pregunta-final");
            else
                await this.getIdEtapa();
        }
        // this.props.history.push("/pregunta-final");
    }

    ComenzarUno = async (e) => {
        //Activar la etapa 1
        this.setState({ haComenzadoUno: true, loading: true })
        await this.activarEtapa(this.state.etapa.idEtapa);
        //Obtener el avance inicial (0)
        //await this.MostrarTextoAvance();
    }

    MostrarTextoAvance = async () => {
        //console.log("Total preguntas: " + this.state.preguntas.length);
        var idPregunta = this.state.preguntas.length > 0 ? this.state.preguntas[0].idPregunta : 0;
        //console.log("id Etapa: " + this.state.etapa.idEtapa + " __ Id Pregunta: " + idPregunta);
        if (this.state.etapa.idEtapa == 4) {
            await this._AvanceCalificacionCategorias()
        } else {
            await PreguntaBrechaDataService.getAvanceV2(this.state.etapa.idEtapa, idPregunta)
                .then(response => {
                    this.setState({ textoAvanceBrecha: response.data + " de " + this.state.countUsersSession + " personas han respondido esta pregunta" })
                });
        }
    }

    refreshData = async () => {
        try {
            const timeoutId = setTimeout(async () => {
                await this._getUsersSession()
                await this.MostrarTextoAvance();
            }, 5000);
        }
        catch (e) {

        }
        finally {
            if (this.state.etapaUnoInicio)
                setTimeout(async () => this.refreshData(), 3000);
        }
    }

    ComenzarDos = async (e) => {
        this.setState({ haComenzadoDos: true, loading: true })
        await this.activarEtapa(this.state.etapa.idEtapa);
        await this._preguntasBrecha()
    }

    ComenzarTres = async (e) => {
        this.setState({ haComenzadoTres: true, loading: true })
        await this.activarEtapa(this.state.etapa.idEtapa);
        this.setState({ loading: true })
        await this._preguntasBrecha();
        this.setState({ loading: false })
        await this._AvanceCalificacionCategorias()
    }



    FinalizarUno = async () => {
        console.log(this.state.etapa, "------->>> Antes de terminar la etapa brecha")

        this.setState({ loading: true })
        await this.desactivarEtapa(this.state.etapa.idEtapa)

    }

    FinalizarDos = async () => {
        this.setState({ textoAvanceBrecha: "_____" })
        await this.desactivarEtapa(this.state.etapa.idEtapa)
    }

    FinalizarTres = async () => {
        clearInterval(this.state.idSetTimeout)
        await this.desactivarEtapa(this.state.etapa.idEtapa)
    }
    //


    ///------------------>>>>>>    Nueva funciones <<<<<<----------------------------------------
    Asignar = () => {
        this.setState({ opcion: 2 })
        this.state.categoria.idCategoria = this.state.respuestasCategorias[this.state.posicion].categoria.id
        this.state.categoria.nombre = this.state.respuestasCategorias[this.state.posicion].categoria.nombre
    }

    Inicializar = () => {
        this.state.categoria.idCategoria = 0
        this.state.categoria.nombre = ""
        this.state.opcion = 0
        this.setState({ editarCategoriaModal: false })
    }

    UpdateCategoria = async () => {
        this.state.categoria.session = this.state.idSesion;
        let res = await PreguntaBrechaDataService.UpdateCategoria(this.state.categoria.idCategoria, this.state.categoria).then((data) => data)
        if (res.status == 200) {
            toast.success("Categoria actualizado correctamente")

            this.state.respuestasCategorias.find((data) => {
                if (data.categoria.id === this.state.categoria.idCategoria) {
                    data.categoria.nombre = res?.data.nombre
                }
            });
            this.Inicializar()
            await this.getCategorias()
        }
    }

    AddCategoria = async () => {
        this.state.categoria.session = this.state.idSesion;

        let res = await PreguntaBrechaDataService.AddCategoria(this.state.categoria).then((data) => data)
        if (res.status === 200) {
            await this.getCategorias()
            toast.success("Categoria agregado correctamente")
            this.Inicializar()

            return res.data
        }
    }

    AddCategoria2 = async () => {
        let res = await PreguntaBrechaDataService.AddCategoria(this.state.categoria).then((data) => data)
        if (res.status === 200) {
            await this.getCategorias()
            this.Inicializar()
            await this._getCategoriasAprobadas()
            return res.data
        }
    }
    removePregunta = async (idCategoria, idPregunta) => {

        //busca la categoria en el json con el id que se paso
        const categoriaPregunta = this.state.respuestasCategorias.find(categoria =>
            categoria.categoria.id === idCategoria);

        if (categoriaPregunta) {

            //crear un nuevo array que contiene solo los elementos que cumplen con la condición especificada
            const preguntaFiltro = categoriaPregunta.respuestas.filter(pregunta => pregunta.id !== idPregunta);

            // Actualiza la lista de categorías sin la pregunta a remover
            const nuevasCategorias = this.state.respuestasCategorias.map(categoria =>
                categoria.categoria.id === categoriaPregunta.categoria.id
                    ? { ...categoria, respuestas: preguntaFiltro }
                    : categoria
            );

            // se busca la pregunta que se va a remover
            const preguntaAMover = categoriaPregunta.respuestas.find(pregunta => pregunta.id === idPregunta);
            preguntaAMover.idCategoriaIA = categoriaPregunta.categoria.id

            // Agrega la pregunta al array de PreguntasSinCategoria
            const nuevaListaPreguntasSinCategoria = [...this.state.respuestasSinCategoria, preguntaAMover];

            // Actualiza el estado con las nuevas listas
            this.setState({
                respuestasCategorias: nuevasCategorias,
                respuestasSinCategoria: nuevaListaPreguntasSinCategoria
            });

        }
    }

    vincularRespuestaCategoria = async (idPregunta, idCategoria, message) => {
        // Crea una copia del estado actual para no mutarlo directamente
        const PreguntasSinCategoriaActualizadas = [...this.state.respuestasSinCategoria];

        // buscar la pregunta a actualizar
        const index = PreguntasSinCategoriaActualizadas.findIndex(pregunta => pregunta.id === idPregunta);

        // si la pregunta fue encontrada hacer los siguinete
        if (index !== -1) {

            let datos = [
                {
                    "idRespuestaCategoria": 0,
                    "idSesion": this.state.idSesion,
                    "idRespuesta": idPregunta,
                    "idCategoria": idCategoria,
                    "idUsuario": 64,
                    "idCategoriaIA": PreguntasSinCategoriaActualizadas[index].idCategoriaIA
                }
            ]

            this.AddRespuestasCategoria(datos, message)

            PreguntasSinCategoriaActualizadas.splice(index, 1)

            this.setState({ respuestasSinCategoria: PreguntasSinCategoriaActualizadas });
        } else {
            // console.error('La pregunta con el ID proporcionado no fue encontrada');
        }

        /***actualizar la respuesta en la base de datos */

    }

    //vincularRespuestaCategoria2 = async (idPregunta, idCategoria, idcategoriaIA) => {
    vincularRespuestaCategoria2 = async (idRespuestafunc) => {
        let datos = []

        if (this.state.selectedCategories.length == 0) {
            this.state.selectedIdPregunta = 0;
            this.state.selectedIdCategoriaIA = 0;
            toast.warning("Seleccione al menos una categoría")
        } else if (this.state.selectedIdPregunta != idRespuestafunc) {
            this.state.selectedCategories.length = 0
            toast.warning("Seleccione al menos una categoria.")
        }else {
            for (var i =0; i < this.state.selectedCategories.length; i++) {
                console.log(this.state.selectedCategories[i])
                var data =  {
                        "idRespuestaCategoria": 0,
                        "idSesion": this.state.idSesion,
                        "idRespuesta": this.state.selectedIdPregunta,
                        "idCategoria": this.state.selectedCategories[i],
                        "idUsuario": 64,
                        "idCategoriaIA": this.state.selectedIdCategoriaIA
                    }

                datos.push(data)
            }
            //console.log(datos, "Datos")
            await this.AddRespuestasCategoria(datos)

            var actualizado = this.state.respuestasCategorias.map(x => ({
                ...x,
                respuestas: x.respuestas.filter(y => y.id !== this.state.selectedIdPregunta)
            }));

            this.setState({ respuestasCategorias: actualizado });

            this.state.selectedIdPregunta = 0;
            this.state.selectedIdCategoriaIA = 0;
            this.state.selectedCategories.length = 0

        }
        //console.log(this.state.selectedCategories, "idCategorias")
        //console.log(this.state.selectedIdPregunta, " idPregunta")
        //console.log(this.state.selectedIdCategoriaIA, "idCategoriaIA")

        //    let datos = [
        //        {
        //            "idRespuestaCategoria": 0,
        //            "idSesion": this.state.idSesion,
        //            "idRespuesta": idPregunta,
        //            "idCategoria": idCategoria,
        //            "idUsuario": 64,
        //            "idCategoriaIA": idcategoriaIA
        //        }
        //    ]

        //    this.AddRespuestasCategoria(datos)

        ////PreguntasSinCategoriaActualizadas.splice(index, 1)
        //var actualizado = this.state.respuestasCategorias.map(x => ({
        //    ...x,
        //    respuestas: x.respuestas.filter(y => y.id !== idPregunta)
        //}));

        //this.setState({ respuestasCategorias: actualizado });
    }

    AddRespuestasCategoria = async (data, message = true) => {
        try {
            let res = await PreguntaBrechaDataService.addRespuestaCategoria(data).then(data => data)
            if (res.status === 200 && res.data >= 1) {
                if (message) {
                    toast.success("Preguntas vinculadas correctamente")
                }

                await this._getCategoriasAprobadas()
            }
        } catch (error) {
            console.log(error);
        }
    }

    Mostrar = () => {
        let propiedad = document.getElementsByClassName(`titulocat`)[0].style

        propiedad.display = 'block'
        propiedad.color = '#2741AA'
    }

    CrearLista = async () => {
        let valor = this.state.respuestasCategorias[this.state.posicion]
        valor.respuestas.map((data) => {
            this.state.datosRespuestasCategoria.push(
                {
                    "idRespuestaCategoria": 0,
                    "idSesion": this.state.idSesion,
                    "idRespuesta": data.id,
                    "idCategoria": valor.categoria.id,
                    "idUsuario": 64,
                    "idCategoriaIA": valor.categoria.id
                }
            )
        })
        await this.AddRespuestasCategoria(this.state.datosRespuestasCategoria)
        this._porcentaje()

        this.state.datosRespuestasCategoria = []
    }

    _porcentaje = () => {

        let porcentaje = Math.floor((100 / this.state.respuestasCategorias.length) * (this.state.posicion + 1))
        this.state.porcentaje = porcentaje
    }

    _getCategoriasAprobadas = async () => {
        let res = await PreguntaBrechaDataService.getCategoriasAprobadas(this.state.etapa.idSesion).then(res => res)
        if (res?.data) {
            this.setState({ CategoriasRespuestas: res.data })
        }
    }

    _valorCategoria = async (e) => {
        if (e?.target.value) {
            this.state.respuestasSinCategoria.map(async (res, k) => await this.vincularRespuestaCategoria(res.id, e?.target.value, false))
            this.setState({ respuestasSinCategoria: [] });
        }
    }

    _getUsersSession = async () => {
        let res = await PreguntaBrechaDataService.ObtenerUsuarios(this.state.etapa?.idSesion).then(res => res)
        if (res?.data) {
            this.setState({ countUsersSession: res.data })
        }
    }

    _getAvanceClasificacionCategoria = async () => {
        let res = await PreguntaBrechaDataService.GetAvance(this.state.etapa.idSesion).then(res => res)
        // console.log(res, "Calificacion");
        return res?.data;
    }

    _AvanceCalificacionCategorias = async () => {
        try {
            this.state.idSetTimeout = setInterval(async () => {
                let respuesta = await this._getAvanceClasificacionCategoria()
                await this._getUsersSession()
                this.setState({ textoAvanceBrecha: respuesta + " de " + this.state.countUsersSession + " personas han calificado las categorias" })
            }, 5000);
        }
        catch (e) {
        }
    }

    _fusionarCategoria = async () => {
        let res = await PreguntaBrechaDataService.FusionarCategoria(this.state.idCategoriaQuitar, this.state.idCategoriaAAgregar, this.state.etapa.idSesion).then(res => res)
    }

    _Fusionar = async () => {
        if (this.state.idCategoriaAAgregar != null && this.state.idCategoriaAAgregar != 0) {
            await this._fusionarCategoria()
            toast.success("Categoría fusionado correctamente")
            this.setState({ modalFusionar: false })
            this.setState({ idCategoriaAAgregar: null })
            this.setState({ idCategoriaQuitar: 0 })
            await this._getCategoriasAprobadas()
        } else
            this.setState({ idCategoriaAAgregar: 0 })
    }

    siguienteCategoria = async () => {
        this.setState({ btnDissabled: true })
        await this.CrearLista()
        this.setState({ posicion: this.state.posicion + 1 })
        this.setState({ btnDissabled: false })
    }


    getRespuestaCategoria = async (item) => {
        console.log(item,"--------->>>>>");
        try {
            var res = await PreguntaBrechaDataService.respuestasCategoria(item).then(res => res)
            if (res?.data) {
                if (res.data.length > 0)
                    this.setState({ respuestasCategoria: res.data })
                console.log(res.data, "******");
            }
        } catch (error) {
            console.log("Error en vista", error);

        }
    }

    modalActions = () => {
        this.setState({ modalRespuestasCategoria: true })
    }

    handleCheckboxChange = (e, idCategoria, idpregunta, idCategoriaIA) => {

        if (this.state.selectedIdPregunta !== 0 && this.state.selectedIdPregunta !== idpregunta)
            this.state.selectedCategories.length = 0

        this.setState({ selectedIdPregunta: idpregunta })
        this.setState({ selectedIdCategoriaIA: idCategoriaIA })
        if (e.target.checked) {
            this.state.selectedCategories.push(idCategoria)
        } else {
            this.state.selectedCategories = this.state.selectedCategories.filter(catId => catId !== idCategoria)
        }
        console.log(this.state.selectedCategories, "####")
    };


    render() {

        const { searchTerm, currentPage, itemsPerPage, CategoriasRespuestas } = this.state;

        // Filtrar los resultados de búsqueda
        const filteredCategorias = CategoriasRespuestas.filter(res =>
            res.categoria.nombre.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Calcular el índice inicial y final para la paginación
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredCategorias.slice(indexOfFirstItem, indexOfLastItem);

        // Número total de páginas
        const totalPages = Math.ceil(filteredCategorias.length / itemsPerPage);



        return (
            //this.state.etapa.finalizado !== true ?
            <div className="page-container">

                <div className={`modalContainer ${this.state.modalRespuestasCategoria ? "active" : ""}`}>


                    <div>
                        <div className="modalMessage modalAgregarCategoria">
                            <span className="close" onClick={() => {
                                this.setState({ modalRespuestasCategoria: false })
                            }}>&times;</span>
                            <div>
                                <h1 className='text-primary margin-bottom-h1'> Respuestas de la categoría</h1>

                                {this.state.respuestasCategoria.length > 0 ? <>
                                    {this.state.respuestasCategoria.map((res, key) => {
                                        return (
                                            <div key={key}>
                                                <div className="modal-content">
                                                    <div className="modal-body"> <strong> {key + 1}.- {res.respuesta}</strong></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </> : "Sin respuestas"}

                            </div>

                            <div className="fin-forcer">
                                    <button className="btn-cancelar2 bt" id="cancelBtn" onClick={() => {
                                      
                                        this.setState({ modalRespuestasCategoria: false })
                                    }}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>


                    <ToastContainer />
                    <Spinner show={this.state.loading} />
                    <div className="title"><h1>Etapa pregunta brecha</h1></div>
                    <div className="TabsContainer">
                        <div className="NavigationTabs">
                            <ul>
                                <li onClick={(e) => {
                                    if (this.state.etapa.idEtapa == e.currentTarget.getAttribute("data-ref"))
                                        changeTab(e);
                                }
                                } data-ref="2">Captura de respuestas</li>
                                <li onClick={(e) => {
                                    if (this.state.etapa.idEtapa == e.currentTarget.getAttribute("data-ref"))
                                        changeTab(e)
                                }} data-ref="3">Categoría de respuestas</li>
                                <li onClick={(e) => {
                                    if (this.state.etapa.idEtapa == e.currentTarget.getAttribute("data-ref"))
                                        changeTab(e)
                                }} data-ref="4">Clasificación de categorías</li>
                            </ul>
                        </div>
                        <div id="brechaContenedor" className="TabsContent">
                            <div className="Tab active" data-ref="2">
                                <div className="questions-container">
                                    <div className="question">
                                        <label htmlFor="">Pregunta brecha</label>
                                        <div className="description text-justify">
                                            <p className="no-border">{this.state.preguntas[0] !== undefined ? this.state.preguntas[0].nombre || "" : ""}</p>
                                        </div>
                                        <label style={{ display: this.state.haComenzadoUno ? "block" : "none" }}>{this.state.textoAvanceBrecha}</label>
                                    </div>
                                </div>
                                {
                                    !this.state.etapa.activo ? <button onClick={(e) => { this.ComenzarUno(e) }} className="btn primary" type="button">Comenzar</button>
                                        : <button onClick={() => { this.FinalizarUno() }} className="btn danger" type="button">Finalizar</button>
                                }
                            </div>
                            <div className="Tab" data-ref="3">
                                <label htmlFor="" className="alert">{this.state.etapa.activo ?

                                    <div>

                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            {/* // ! CATEGORIAS APROBADAS */}

                                            <Input
                                            type="text"
                                            placeholder="Buscar categoría..."
                                            value={searchTerm}
                                            onChange={this.handleSearch}
                                            style={{ marginBottom: '20px' }}
                                            />

                                            <table class="custom-table">
                                                <caption>Categorías aprobadas {this.state.CategoriasRespuestas.length}</caption>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Categoría</th>
                                                        <th scope="col">Respuestas</th>
                                                        <th scope="col">Opción</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.map((res, k) => (
                                                        <tr key={k}>
                                                            <td style={{ fontSize: '20px' }} onClick={async () => {
                                                                this.getRespuestaCategoria(res.categoria.idCategoria)
                                                                this.modalActions()
                                                            }}>{res.categoria.nombre}</td>
                                                            <td>
                                                                <p style={{ margin: 0, fontSize: '12px' }}><strong>Respuestas</strong></p>
                                                                <span>{res.respuesta}</span>
                                                            </td>
                                                            <td>
                                                                <button className="btn-primary-custom" onClick={() => {
                                                                    this.setState({ idCategoriaQuitar: res.categoria.idCategoria });
                                                                    this.setState({ modalFusionar: true });
                                                                }}>Fusionar</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            {this.state.posicion < this.state.respuestasCategorias.length ?
                                                <>
                                                    <div class="conte">
                                                        <div className='flip-card'>
                                                            <div className="flip-card-inner">
                                                                <div className="flip-card-front">
                                                                    <h1>CATEGORÍA</h1>
                                                                </div>
                                                                <div className="flip-card-back flip-card-back-heigth">
                                                                    <div className='tituloCategoria'>
                                                                        <h1 id='h1NombreCategoria' >{this.state.respuestasCategorias[this.state.posicion]?.categoria.nombre}</h1>
                                                                        <div className='marginBotonTitulo'>
                                                                            {/* <button className="btn-agregar" onClick={() => this.Mostrar()}>
                                                            <img src="/img/agregar.svg" alt=""></img>
                                                            <span>Mostrar</span>
                                                        </button> */}
                                                                            <button className="btn-agregar" onClick={() => {
                                                                                this.Inicializar()
                                                                                this.setState({ editarCategoriaModal: true })
                                                                                this.state.posicion < this.state.respuestasCategorias.length ?
                                                                                    this.Asignar()
                                                                                    :
                                                                                    this.setState({ opcion: 1 })
                                                                            }}>
                                                                                <img src="/img/agregar.svg" alt=""></img>
                                                                                <span>{this.state.posicion < this.state.respuestasCategorias.length ? 'Editar' : 'Agregar'}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button className="btn-agregar " onClick={() => {
                                                                this.Inicializar()
                                                                this.setState({ editarCategoriaModal: true })

                                                                this.setState({ opcion: 1 })
                                                            }}>
                                                                <img src="/img/agregar.svg" alt=""></img>
                                                                <span>{'Agregar'}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>

                                                :
                                                <div className=''>
                                                    <div className="">
                                                        <div className="">
                                                            <div className='tituloCategoria'>
                                                                <div className='marginBotonTitulo'>
                                                                    {/*{this.state.respuestasSinCategoria.length != 0 &&*/}

                                                                    {/*    <>*/}
                                                                    <button className="btn-agregar" onClick={() => {
                                                                        this.Inicializar()
                                                                        this.setState({ editarCategoriaModal: true })
                                                                        this.state.posicion < this.state.respuestasCategorias.length ?
                                                                            this.Asignar()
                                                                            :
                                                                            this.setState({ opcion: 1 })
                                                                    }}>
                                                                        <img src="/img/agregar.svg" alt=""></img>
                                                                        <span>{this.state.posicion < this.state.respuestasCategorias.length ? 'Editar' : 'Agregar'}</span>
                                                                    </button>
                                                                    <select className="form-select form-select-sm custom-select" onChange={this._valorCategoria}>
                                                                        <option value={''} selected>Asignar todos a la categoría</option>
                                                                        {this.state.categorias.map((res, k) => {
                                                                            return (
                                                                                <>
                                                                                    <option key={k} value={res.idCategoria}>{res.nombre}</option>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {/*    </>*/}
                                                                    {/*}*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                            {this.state.porcentaje < 100 && this.state.posicion < this.state.respuestasCategorias.length &&
                                                <div className="progress">
                                                    <div className="progress-bar" id='porcentaje' style={{ width: `${this.state.porcentaje}%` }}>
                                                        <span className="progress-bar-text">{this.state.porcentaje}%</span>
                                                    </div>
                                                </div>}
                                            <div className="contenedor-tags-grid">
                                                {this.state.posicion < this.state.respuestasCategorias.length ? <>
                                                    {this.state.respuestasCategorias[this.state.posicion]?.respuestas.map((data, key) => {
                                                        return (

                                                            <div className='contendedorTarjetas' key={key}>
                                                                <div className="card">
                                                                    <div className="card1">
                                                                        {/* <p>This is heading</p> */}
                                                                        <p className="small"><b>{data.respuesta}</b></p>
                                                                        <div className="">
                                                                            {/*{this.state.posicion < this.state.respuestasCategorias.length ? <div className="go-arrow">*/}
                                                                            {/*    <a onClick={() => this.removePregunta(this.state.respuestasCategorias[this.state.posicion].categoria.id, data.id)}>*/}
                                                                            {/*        <img src="/img/cancelar.svg" alt="" />*/}
                                                                            {/*    </a>*/}

                                                                            {/*</div> : <></>}*/}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*{this.state.posicion < this.state.respuestasCategorias.length ? <></> :*/}
                                                                <div className="dropdown">
                                                                    <button className="dropbtn">
                                                                        <img src="/img/flecha.svg" alt="" />
                                                                    </button>
                                                                    <div className="dropdown-content">
                                                                        {this.state.categorias.map((dataCat, key) => {
                                                                            return (
                                                                                <div key={key} className="checksCategorias">
                                                                                    <input
                                                                                        className="inputCatBrecha"
                                                                                        type="checkbox"
                                                                                        onChange={(e) => this.handleCheckboxChange(e, dataCat.idCategoria, data.id, this.state.respuestasCategorias[this.state.posicion]?.categoria.id)}
                                                                                        checked={this.state.selectedCategories.includes(dataCat.idCategoria) && this.state.selectedIdPregunta === data.id}
                                                                                    />
                                                                                    <span>{dataCat.nombre}</span>
                                                                                    {/* onClick={() => this.vincularRespuestaCategoria2(data.id, dataCat.idCategoria, this.state.respuestasCategorias[this.state.posicion]?.categoria.id)} */}
                                                                                </div>

                                                                            )
                                                                        })}
                                                                        <button className="btn-primary-custom" style={{ marginTop: 20, marginBottom:20 }} onClick={async () => this.vincularRespuestaCategoria2(data.id)}>Agregar</button>
                                                                    </div>
                                                                </div>
                                                                {/*}*/}
                                                            </div>
                                                        )
                                                    })}
                                                </> : <>

                                                    {this.state.respuestasSinCategoria?.map((data, key) => {
                                                        return (
                                                            <div className='contendedorTarjetas lastChild-respSinCate' key={key}>
                                                                <div className="card">
                                                                    <a className="card1">
                                                                        {/* <p>This is heading</p> */}
                                                                        <p className="small"><b>{data.respuesta}</b></p>
                                                                        <div className="go-corner">
                                                                            {this.state.posicion < this.state.respuestasCategorias.length ? <div className="go-arrow">
                                                                                <img src="/img/cancelar.svg" alt="" />
                                                                            </div> : <></>}

                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                {this.state.posicion < this.state.respuestasCategorias.length ? <></> :
                                                                    <div className="dropdown">
                                                                        <button className="dropbtn">
                                                                            <img src="/img/flecha.svg" alt="" />
                                                                        </button>
                                                                        <div className="dropdown-content">
                                                                            {this.state.categorias.map((dataCat, key) => {
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <a onClick={() => this.vincularRespuestaCategoria(data.id, dataCat.idCategoria)} >{dataCat.nombre}</a>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })}

                                                </>}
                                            </div>
                                            {this.state.posicion < this.state.respuestasCategorias.length &&
                                                <div className='botonSiguiente'>
                                                    <div className='contentBtn'>

                                                        {this.state.btnDissabled === true && <button className='btn primary btnSiguiente'> ...... </button>}
                                                        {/* <CircularProgress size={25} color="inherit" /> */}

                                                        {this.state.btnDissabled === false && <button onClick={async () => {
                                                            await this.siguienteCategoria()
                                                        }} className='btn primary btnSiguiente'>Siguiente</button>}
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>

                                   

                                       


                                    </div>

                                    : "¡Click para comenzar a agrupar respuestas por categoría!"}</label>
                                {
                                    !this.state.etapa.activo ? <button onClick={async (e) => { await this.ComenzarDos(e) }} className="btn primary" type="button">Comenzar</button>
                                        : <>
                                            {this.state.posicion >= this.state.respuestasCategorias.length && this.state.respuestasSinCategoria.length == 0 &&
                                                <button onClick={() => { this.FinalizarDos() }} className="btn danger" type="button">Finalizar</button>
                                            }
                                        </>
                                }

                        </div> {/*Finaliza acá*/}


                            <div className="Tab" data-ref="4">

                                <label className='alert' style={{ display: this.state.haComenzadoTres ? "block" : "none" }}>{this.state.textoAvanceBrecha}</label>

                                <label htmlFor="" className="alert">{this.state.etapa.activo ? "¡Ahora los asistentes pueden recargar el sitio!" : "¡Click para comenzar a clasificar las categorías!"}</label>
                                {
                                    !this.state.etapa.activo ? <button onClick={(e) => { this.ComenzarTres(e) }} className="btn primary" type="button">Comenzar</button>
                                        : <button onClick={() => { this.FinalizarTres() }} className="btn danger" type="button">Finalizar y Terminar etapa brecha</button>
                                }
                            </div>
                        </div>
                    </div>

                    {/* // ! MODAL FUSIONAR */}
                    <div className={`modalContainer ${this.state.modalFusionar ? "active" : ""}`}>


                        <div>
                            <div className="modalMessage modalAgregarCategoria">
                                <span className="close" onClick={() => {
                                    this.setState({ modalFusionar: false })
                                    this.setState({ idCategoriaAAgregar: null })
                                }}>&times;</span>
                                <div>
                                    <h1 className='text-primary margin-bottom-h1'> Fusionar categoría</h1>

                                    <div className="input-group">
                                        <select className="form-select form-select-sm custom-select" onChange={(e) => { this.setState({ idCategoriaAAgregar: e.target.value }) }} style={{ marginBottom: `${10}%`, marginTop: `${5}%`, }}>
                                            <option value={0} selected>Seleccione a que categoría fusionar:</option>
                                            {this.state.CategoriasRespuestas.map((res, k) => {
                                                if (res.categoria.idCategoria != this.state.idCategoriaQuitar) {
                                                    return (
                                                        <>
                                                            <option key={k} value={res?.categoria?.idCategoria}>{res?.categoria?.nombre}</option>
                                                        </>
                                                    )
                                                }

                                            })}
                                        </select>

                                        <label className='alert' style={{ display: this.state.idCategoriaAAgregar == 0 ? "block" : "none", color: 'red', fontSize: '12px' }}>Seleccione una categoría a fusionar</label>

                                    </div>

                                </div>

                                <div className="fin-forcer">
                                    {this.state.btnDissabled === false &&
                                        <button className="btn-cancelar2 bt" id="cancelBtn" onClick={() => {
                                            this.setState({ idCategoriaQuitar: 0 })
                                            this.setState({ idCategoriaAAgregar: null })
                                            this.setState({ modalFusionar: false })
                                        }}>Cancelar</button>}

                                    {this.state.btnDissabled === false &&
                                        <button button className="btn-guaradar bt" onClick={async () => {
                                            this.setState({ btnDissabled: true })
                                            await this._Fusionar()
                                            this.setState({ btnDissabled: false })
                                        }}>Fusionar</button>
                                    }
                                    {this.state.btnDissabled === true && <button className='btn primary btnSiguiente'> ...... </button>}
                                    {/* <CircularProgress size={25} color="inherit" /> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* // ! MODAL AGREGAR O EDITAR CATEGORIAS */}

                    <div className={`modalContainer ${this.state.editarCategoriaModal ? "active" : ""}`}>


                        <div>
                            <div className="modalMessage modalAgregarCategoria">
                                <span className="close" onClick={() => this.setState({ editarCategoriaModal: false })}>&times;</span>
                                <div>
                                    <h1 className='text-primary margin-bottom-h1'>{this.state.opcion === 1 ? "Agregar " : "Editar "}Categoria</h1>

                                    <div className="input-group" style={{ marginBottom: `${10}%`, marginTop: `${5}%`, }}>
                                        <label htmlFor="" className="labelCategoria">Categoría</label>
                                        <input autoComplete="off" className="input" onChange={(e) => this.setState({
                                            categoria: {
                                                ...this.state.categoria,
                                                nombre: e.target.value
                                            }
                                        })} value={this.state.categoria.nombre} type="text" placeholder="Categoría" />
                                        <div></div>
                                    </div>
                                    {/* <label htmlFor="">Categoria</label>
                                    <p><input className="form-control" onChange={(e) => this.setState({
                                        categoria: {
                                            ...this.state.categoria,
                                            nombre: e.target.value
                                        }
                                    })} value={this.state.categoria.nombre} type="text" placeholder="Categoría" /></p> */}
                                </div>
                                <div className="fin-forcer">
                                    <button className="btn-cancelar2 bt" id="cancelBtn" onClick={() => this.setState({ editarCategoriaModal: false })}>Cancelar</button>
                                    <button className="btn-guaradar bt" onClick={() => this.state.opcion === 1 ? this.AddCategoria() : this.UpdateCategoria()}>Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                        <div className="modalMessage">
                            <div className="modalHeader">
                                <p>ABP Virtual</p>
                                <small>Sistema de consulta participativa</small>
                            </div>
                            <div className="modalBody">
                                <p>El sistema de consulta participativa ABP Virtual permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                                <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                            </div>
                            <div className="modalFooter">
                                <button type="button" onClick={() => { this.setState({ infoModal: false }) }} className="btn primary">Cerrar mensaje</button>
                            </div>
                        </div>
                    </div>
                    <div className="leftActions">
                        <button onClick={() => { navigator.clipboard.writeText(localStorage.getItem("abplink")) }}><i className="fas fa-link" type="button"></i></button>
                        <button type="button" onClick={() => { this.setState({ infoModal: true }) }}><i className="fas fa-info" type="button"></i></button>
                    </div>
                </div>
                //:
                //<div className="page-container">
                //    <br />
                //    La etapa no esta activa
                //</div>
        )
    }
}

function changeTab(e) {
    let tabs = document.querySelectorAll(".NavigationTabs ul li");
    tabs.forEach((y) => {
        y.classList.remove("active");
    });
    tabs.forEach((z) => {
        if (document.querySelector(`.TabsContainer .TabsContent .Tab[data-ref="${z.getAttribute("data-ref")}"]`)) {
            document.querySelector(`.TabsContainer .TabsContent .Tab[data-ref="${z.getAttribute("data-ref")}"]`).classList.remove("active");
        }
    });
    e.currentTarget.classList.add("active");
    if (document.querySelector(`.TabsContainer .TabsContent .Tab[data-ref="${e.currentTarget.getAttribute("data-ref")}"]`)) {
        document.querySelector(`.TabsContainer .TabsContent .Tab[data-ref="${e.currentTarget.getAttribute("data-ref")}"]`).classList.add("active");
    }
}

export default ControlPreguntaBrecha;