import React from "react";

const HomeLayout = ({ children, ...rest }) => {

    return (
        <div>
            <header>
                <div className="logo">
                    <img src="/img/logo.png" alt="" />
                </div>
            </header>
            <main>
                {children}
            </main>
            <footer>
                
            </footer>
        </div>
    );
};
export default HomeLayout;
