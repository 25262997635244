import axios from "axios";
import AuthLogin from "../Utils/AuthLogin";

let auth = new AuthLogin();

let instance = axios.create({
    baseURL : 'https://api.tinyurl.com',
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    async config => {
        const token = "Bearer 7CLDCMDwEcUSHpzaJkL4SWTVl7CgOf3MYlfRaZ98dqYcJ7HB664uRtX2X7gn";
        config.headers.Authorization = token;
        return config;
    });

export { instance as default };